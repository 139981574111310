<template>
    <div>
        <!-- # 상단 -->
        <div
            class="d-flex align-center pl-5 pr-4 pt-3 pb-3 mobile"
        >
            <!-- 제목 -->
            <p
                style="font-size:16px; font-weight:bold;"
                class="mb-0"
            >
                공식대회
                <span style="color:#F8468D; font-size:7px; vertical-align:super; font-weight:400;">BETA</span>
            </p>

            <!-- 로딩 -->
            <!-- <v-progress-circular
                v-if="loading"
                class="ml-2"
                indeterminate
                color="primary"
                size="18"
                width="2"
            ></v-progress-circular> -->

            <v-spacer></v-spacer>

            <div
                class="d-flex"
            >
                <v-btn
                    depressed
                    dark
                    class="rounded-10 px-3"
                    style="font-size:12px;"
                    color="#adafca"
                    height="28"
                    @click="$router.push('/official_league/deck_rank/list')"
                >
                    덱 순위
                </v-btn>

                <v-btn
                    depressed
                    dark
                    class="rounded-10 px-3 ml-1"
                    style="font-size:12px;"
                    color="#adafca"
                    height="28"
                    @click="$router.push('/official_league/player/list')"
                >
                    플레이어
                </v-btn>

                <v-btn
                    depressed
                    dark
                    class="rounded-10 px-3 ml-1"
                    style="font-size:12px;"
                    color="primary"
                    height="28"
                    @click="dialog.detail = true"
                >
                    <v-icon size="14" class="mt-2px mr-1">mdi-magnify</v-icon>
                    상세
                </v-btn>
            </div>
        </div>

        <!-- # 검색 -->
        <v-sheet
            class="py-2 px-3 rounded-10"
        >
            <div
                class="d-flex rounded-15"
            >
                <v-sheet
                    outlined
                    class="d-flex rounded-15 px-1 mobile flex-grow-1"
                >
                    <v-select
                        style="width:130px;"
                        class="rounded-15 shrink"
                        solo
                        flat
                        dense
                        hide-details
                        :items="select_list.text_type"
                        v-model="keyword.text_type"
                        @change="search(); text_type_changed()"
                    ></v-select>
                    <v-text-field
                        class="rounded-15 flex-grow-1"
                        v-model="keyword.text"
                        solo
                        flat
                        dense
                        hide-details
                        placeholder="검색어"
                        @keyup.enter="search(); text_changed()"
                    ></v-text-field>
                </v-sheet>

                <!-- 검색 버튼 -->
                <v-btn
                    depressed
                    dark
                    height="40"
                    class="ml-2 rounded-10"
                    color="primary"
                    small
                    @click="search()"
                >
                    <v-icon size="20">mdi-magnify</v-icon>
                </v-btn>
            </div>

            <div
                class="d-flex mt-2"
            >
                <v-chip
                    style="width:58px; height:26px;"
                    class="py-0 text-center rounded-15 d-flex justify-center"
                    x-small
                    :style="keyword.date_type == '전체'? 'background:#e6fafc; border:1px solid #23D2E2;':'background:#f4f4f4; border:1px solid #B3B3C2;'"
                    @click="keyword.date_type = '전체'; date_type_changed(); search()"
                >
                    <span :style="keyword.date_type == '전체'? 'color:#23D2E2;':'color:#B3B3C2;'">전체</span>
                </v-chip>

                <v-chip
                    style="width:58px; height:26px;"
                    class="py-0 text-center rounded-15 d-flex justify-center mx-2"
                    x-small
                    :style="keyword.date_type == '완료됨'? 'background:#e6fafc; border:1px solid #23D2E2;':'background:#f4f4f4; border:1px solid #B3B3C2;'"
                    @click="keyword.date_type = '완료됨'; date_type_changed(); search()"
                >
                    <span :style="keyword.date_type == '완료됨'? 'color:#23D2E2;':'color:#B3B3C2;'">완료됨</span>
                </v-chip>

                <v-chip
                    style="width:58px; height:26px; background:#f4f4f4; border:1px solid #B3B3C2;"
                    class="py-0 text-center rounded-15 d-flex justify-center"
                    x-small
                    :style="keyword.date_type == '예정됨'? 'background:#e6fafc; border:1px solid #23D2E2;':'background:#f4f4f4; border:1px solid #B3B3C2;'"
                    @click="keyword.date_type = '예정됨'; date_type_changed(); search()"
                >
                    <span :style="keyword.date_type == '예정됨'? 'color:#23D2E2;':'color:#B3B3C2;'">예정됨</span>
                </v-chip>
            </div>
        </v-sheet>

        <!-- # 검색조건 표시 & list_type 버튼 -->
        <v-sheet
            class="py-2 px-3 rounded-10 d-flex align-center mt-2"
        >
            <v-btn
                class="mr-1 rounded-10"
                color="primary"
                height="32"
                x-small
                depressed
                @click="reset_keyword()"
            >
                <v-icon size="16">mdi-reload</v-icon>
            </v-btn>

            <!-- 상세조건 목록 -->
            <div
                class="d-flex flex-wrap"
                style="margin-top:-4px; width:200px;"
            >
                <v-chip
                    v-for="item in keyword_list" :key="item.league_type"
                    class="px-1 py-3 ml-1 mt-1 rounded-5"
                    small
                    style="background:#e6fafc; border:1px solid #23D2E2;"
                >
                    <v-icon
                        small
                        class="mr-1"
                        color="#F8468D"
                        @click="removeKeyword(item)"
                    >
                        mdi-close
                    </v-icon>
                    <span style="color:#23D2E2;">
                        {{pretty_keyword(item.type)}} : {{item.value}}
                    </span>
                </v-chip>
            </div>

            <v-spacer></v-spacer>

            <!-- 목록 방식 (card) -->
            <v-btn
                class="px-1 rounded-15 ml-2"
                dark
                text
                depressed
                style="cursor:pointer;"
                :color="list_type=='card'? '#23D2E2':'#DFDFDF'"
                @click="list_type='card'; list_type_changed();"
            >
                <v-icon
                    size="32"
                >
                    mdi-image
                </v-icon>
            </v-btn>

            <!-- 목록 방식 (list) -->
            <v-btn
                class="px-1 rounded-15"
                dark
                text
                depressed
                style="cursor:pointer;"
                :color="list_type=='list'? '#23D2E2':'#DFDFDF'"
                @click="list_type='list'; list_type_changed();"
            >
                <v-icon
                    size="32"
                >
                    mdi-menu
                </v-icon>
            </v-btn>
        </v-sheet>

        <!-- # 목록 -->
        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            class="d-flex mx-auto my-10"
            size="80"
        ></v-progress-circular>
        <div
            v-if="!loading && list.length"
            class="mt-1 text-center"
        >
            <!-- # list_type : card -->
            <v-row
                v-if="list_type == 'card'"
                no-gutters
                style="margin:0 -4px;"
            >
                <v-col
                    cols="12"
                    v-for="item in currentPageList" :key="item.id"
                    class="text-caption pa-1 px-6 py-2"
                >
                    <v-sheet
                        class="rounded-15 pt-4"
                        style="box-shadow:0px 2px 4px 2px #dedede; cursor:pointer;"
                        @click="$router.push('/official_league/read?id='+item.id)"
                    >
                        <!-- 이미지 -->
                        <v-img
                            contain
                            height="72"
                            max-width="200"
                            class="mx-auto mb-1"
                            :src="'/upload/official_league/'+item.image"
                        ></v-img>
                        <p
                            v-if="item.age_group"
                            style="font-size:10px; font-weight:600; margin-bottom:-2px;"
                            :style="$age_group_color(item.age_group)"
                        >
                            {{item.age_group}}
                        </p>
                        <p
                            style="font-size:14px; font-weight:600; margin-bottom:-3px;"
                        >
                            {{item.title_kr}}
                        </p>
                        <p
                            style="font-size:12px; color:#B3B3C2; font-weight:500; margin-bottom:-3px;"
                        >
                            {{item.title_en}}
                        </p>
                        <p
                            style="font-size:10px; color:#615DFA; font-weight:500; margin-bottom:4px; height:21px;"
                        >
                            {{computed_player_kr(item)}}
                        </p>

                        <div
                            class="d-flex"
                        >
                            <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-sheet
                                        height="52"
                                        width="33.333%"
                                        color="#f3f3f3"
                                        style="border-top:4px solid #F8468D;"
                                        class="rounded-bl-15"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-img
                                            v-if="item.league_nation"
                                            width="36"
                                            height="26"
                                            class="rounded-5 mx-auto mt-3"
                                            style="box-shadow:0px 2px 4px #ddd;"
                                            :src="'/upload/flags/'+item.league_nation.toLowerCase()+'.svg'"
                                        ></v-img>
                                    </v-sheet>
                                </template>
                                <div class="text-center pa-1">
                                    {{$country_name(item.league_nation, select_list.country_list)}}
                                </div>
                            </v-tooltip>
                            <v-sheet
                                height="52"
                                width="33.333%"
                                color="#f3f3f3"
                                style="border-top:4px solid #23D2E2; border-left:1px solid #e9e9e9; border-right:1px solid #e9e9e9;"
                                class="pt-14px font-weight-bold"
                            >
                                {{dayjs(item.start_date).format('YYYY-MM-DD')}}
                            </v-sheet>
                            <v-sheet
                                height="52"
                                width="33.333%"
                                color="#f3f3f3"
                                style="border-top:4px solid #FFE165;"
                                class="rounded-br-15 pt-14px font-weight-bold"
                            >
                                <v-icon size="18" color="black">mdi-account-multiple</v-icon>
                                {{$toComma(item.total)}}명
                            </v-sheet>
                        </div>
                    </v-sheet>
                </v-col>
            </v-row>

            <!-- # list_type : list -->
            <v-sheet
                v-if="list_type == 'list'"
                class="rounded-15 pb-4 mb-2 mt-2"
                :key="componentKey+'listTypeList'"
            >
                <!-- 목록 상단 -->
                <v-sheet
                    height="40"
                    color="#F0F3F5"
                    class="pt-1 d-flex rounded-t-15"
                >
                    <!-- 날짜 -->
                    <div
                        :class="sequence == '날짜'? 'primary--text':''"
                        class="text-center mt-1 pl-2"
                        style="width:17%; font-size:12px; position:relative; cursor:pointer;"
                        @click="sequence == '날짜'? (sequence = '날짜', align_date = !align_date) : sequence = '날짜'"
                    >
                        날짜
                        <v-icon
                            size="20"
                            class="mb-2px"
                            :color="sequence == '날짜'? 'primary':''"
                        >
                            {{align_date? 'mdi-chevron-up':'mdi-chevron-down'}}
                        </v-icon>
                        <div
                            v-if="sequence == '날짜'"
                            class="primary mx-auto"
                            style="height:2px; width:100%; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                        ></div>
                    </div>

                    <!-- 국가 -->
                    <div
                        style="width:17%; font-size:12px;"
                        class="text-center mt-1"
                    >
                        국가
                    </div>

                    <!-- 대회 이름 -->
                    <div
                        :class="sequence == '대회 이름'? 'primary--text':''"
                        class="text-center mt-1 pl-1"
                        style="width:66%; font-size:12px; position:relative; cursor:pointer;"
                        @click="sequence == '대회 이름'? (sequence = '대회 이름', align_title = !align_title) : sequence = '대회 이름'"
                    >
                        대회 이름
                        <v-icon
                            size="20"
                            class="mb-2px"
                            :color="sequence == '대회 이름'? 'primary':''"
                        >
                            {{align_title? 'mdi-chevron-up':'mdi-chevron-down'}}
                        </v-icon>
                        <div
                            v-if="sequence == '대회 이름'"
                            class="primary mx-auto"
                            style="height:2px; width:200px; position:absolute; bottom:0px; left:50%; transform:translateX(-50%);"
                        ></div>
                    </div>
                </v-sheet>

                <!-- 목록 값 -->
                <div>
                    <v-sheet
                        v-for="item in currentPageList" :key="item.id"
                        height="48"
                        class="d-flex align-center ma-0 pa-0"
                        style="position:relative; cursor:pointer; width:100%;"
                        @click="$router.push('/official_league/read?id='+item.id)"
                    >
                        <!-- 날짜 -->
                        <div
                            style="width:17%; font-size:10px; height:48px; border-bottom:1px solid #eee;"
                            class="text-center d-flex align-center justify-center"
                        >
                            {{dayjs(item.start_date).format('YY-MM-DD')}}
                        </div>

                        <!-- 국가 -->
                        <div
                            style="width:17%; font-size:12px; height:48px; border-bottom:1px solid #eee; cursor:pointer;"
                            class="text-center d-flex align-center justify-center font-weight-medium primary--text"
                        >
                            <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-16">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-sheet
                                        height="24"
                                        width="32"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-img
                                            v-if="item.league_nation"
                                            height="24"
                                            width="32"
                                            class="rounded-5 mx-auto"
                                            style="box-shadow:0px 2px 4px #ddd;"
                                            :src="'/upload/flags/'+item.league_nation.toLowerCase()+'.svg'"
                                        ></v-img>
                                    </v-sheet>
                                </template>
                                <div class="text-center pa-1">
                                    {{$country_name(item.league_nation, select_list.country_list)}}
                                </div>
                            </v-tooltip>
                        </div>

                        <!-- 대회 이름 -->
                        <div
                            style="width:68%; font-size:12px; height:48px; border-bottom:1px solid #eee; cursor:pointer;"
                            class="text-start d-flex align-center font-weight-medium flex-grow-1 pr-2"
                        >
                            <div
                                class="d-flex align-center flex-grow-1 text-truncate"
                                style="width:auto;"
                            >
                                <div class="d-flex mr-1">
                                    <div>
                                        <!-- 이미지 -->
                                        <v-img
                                            contain
                                            height="20"
                                            max-width="30"
                                            class="mx-auto mb-1"
                                            :src="'/upload/official_league/'+item.image"
                                        ></v-img>
                                    </div>
                                </div>
                                <div
                                    style="width:auto;"
                                >
                                    <p
                                        class="text-truncate"
                                        style="font-size:9px; font-weight:600; width:auto; margin-bottom:-1px;"
                                    >
                                        <span
                                            v-if="item.age_group"
                                            :style="$age_group_color(item.age_group)"
                                            class="mr-2px font-weight-bold"
                                            style="font-size:9px;"
                                        >
                                            {{item.age_group}}
                                        </span>
                                        {{item.title_kr}}
                                    </p>
                                    <p
                                        style="font-size:8px; color:#B3B3C2; font-weight:500; margin-bottom:0px;"
                                    >
                                        {{item.title_en}}
                                    </p>
                                </div>
                            </div>

                            <v-spacer></v-spacer>
                            <!-- 레귤레이션 -->
                            <div
                                style="width:60px; flex-shrink:0;"
                                class="d-flex justify-center align-center"
                            >
                                <v-img
                                    v-if="item.regulation"
                                    class="mx-auto"
                                    height="40"
                                    width="40"
                                    contain
                                    :src="require('@/assets/official_league/'+item.regulation+'.png')"
                                ></v-img>
                            </div>
                        </div>
                    </v-sheet>
                </div>
            </v-sheet>
        </div>

        <!-- # 결과 없을 시 -->
        <div
            v-if="!loading && !list.length"
            class="mt-6 text-center py-16"
        >
            <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
            <p style="color:#ccc">검색 결과가 없습니다</p>
        </div>

        <!-- # 페이징 -->
        <v-pagination
            class="d-flex mx-auto mobile"
            v-model="page"
            :length="pageCount"
            :total-visible="7"
        ></v-pagination>

        <div
            class="d-flex justify-end mx-3 mb-4 mt-1"
        >
            <!-- 대회추가 -->
            <v-btn
                v-if="$store.state.type == '관리자' || ($store.state.type == '운영진' && $store.state.permission_official_league)"
                width="80"
                color="icu_purple"
                depressed
                dark
                small
                class="rounded-10"
                to="/official_league/write"
            >
                대회추가
            </v-btn>
        </div>

        <!-- # 상세조건 (dialog) -->
        <v-dialog
            v-model="dialog.detail"
            width="auto"
            fullscreen
        >
            <!-- # PC -->
            <v-sheet
                class="px-8 pt-10 pb-3"
                color="#F8F7FB"
                style="border:2px solid #F8468D; position:relative;"
            >
                <!-- # 닫기 -->
                <v-icon
                    size="24"
                    style="position:absolute; right:8px; top:8px; z-index:999;"
                    color="#F8468D"
                    @click="dialog.detail = false"
                >
                    mdi-close
                </v-icon>

                <!-- # 날짜 -->
                <v-sheet
                    class="rounded-10 pa-5 pt-4 pb-2 mb-2"
                >
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">날짜</span>

                    <div class="d-flex my-2 mobile">
                        <v-select
                            height="34"
                            class="rounded-10"
                            dense
                            outlined
                            hide-details
                            :items="select_list.date"
                            v-model="keyword.date"
                            :menu-props="{ offsetY: true }"
                            @change="updateKeywordList('날짜', keyword.date)"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-1 pt-1">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- # 대회 유형 -->
                <v-sheet
                    class="rounded-10 pa-5 pt-4 pb-2 mb-2"
                >
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">대회 유형</span>

                    <div class="d-flex my-2 mobile">
                        <v-select
                            height="34"
                            class="rounded-10"
                            dense
                            outlined
                            hide-details
                            :items="select_list.league_type"
                            item-text="text"
                            item-value="value"
                            v-model="keyword.league_type"
                            :menu-props="{ offsetY: true }"
                            @change="updateKeywordList('대회 유형', keyword.league_type)"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-1 pt-1">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- # 국가별 (대륙) -->
                <v-sheet
                    class="rounded-10 pa-5 pt-4 pb-2 mb-2"
                >
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">국가별 (대륙)</span>

                    <div class="d-flex my-2 mobile">
                        <v-select
                            height="34"
                            class="rounded-10"
                            dense
                            outlined
                            hide-details
                            :items="select_list.continent"
                            v-model="keyword.continent"
                            :menu-props="{ offsetY: true }"
                            @change="updateKeywordList('국가별 (대륙)', keyword.continent)"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-1 pt-1">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- # 시즌 (환경) -->
                <v-sheet
                    class="rounded-10 pa-5 pt-4 pb-2 mb-2"
                >
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">시즌 (환경)</span>

                    <div class="d-flex my-2 mobile">
                        <v-select
                            height="34"
                            class="rounded-10"
                            dense
                            outlined
                            hide-details
                            :items="select_list.season_nation"
                            v-model="keyword.season_nation"
                            :menu-props="{ offsetY: true }"
                            @change="keyword_list = keyword_list.filter(k => k.type !== 'season'); keyword.season = ''"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-1 pt-1">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                    <div class="d-flex mt-2 mb-2 mobile">
                        <v-select
                            v-if="season_list.length"
                            height="34"
                            class="rounded-10"
                            dense
                            outlined
                            hide-details
                            :items="season_list"
                            v-model="keyword.season"
                            :menu-props="{ offsetY: true }"
                            @change="updateKeywordList('시즌 (환경)', keyword.season)"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-1 pt-1">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>

                        <v-select
                            v-else
                            height="34"
                            class="rounded-10"
                            dense
                            outlined
                            hide-details
                            readonly
                            placeholder="국가를 선택하세요"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-1 pt-1">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- # 연령 -->
                <v-sheet
                    class="rounded-10 pa-5 pt-4 pb-2 mb-2"
                >
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">연령</span>

                    <div class="d-flex my-2 mobile">
                        <v-select
                            height="34"
                            class="rounded-10"
                            dense
                            outlined
                            hide-details
                            :items="select_list.age_group"
                            v-model="keyword.age_group"
                            :menu-props="{ offsetY: true }"
                            @change="updateKeywordList('연령', keyword.age_group)"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-1 pt-1">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- # 레귤레이션 -->
                <v-sheet
                    class="rounded-10 pa-5 pt-4 pb-2 mb-2"
                >
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">레귤레이션</span>

                    <div class="d-flex my-2 mobile">
                        <v-select
                            height="34"
                            class="rounded-10"
                            dense
                            outlined
                            hide-details
                            :items="select_list.regulation"
                            item-text="text"
                            item-value="value"
                            v-model="keyword.regulation"
                            :menu-props="{ offsetY: true }"
                            @change="updateKeywordList('레귤레이션', keyword.regulation)"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-1 pt-1">{{item.text}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- # 버튼 -->
                <div class="mt-2 pa-2 d-flex justify-center">
                    <!-- 검색 -->
                    <v-btn
                        width="110"
                        height="40"
                        dark
                        depressed
                        color="primary"
                        class="font-weight-bold rounded-10 mr-2"
                        @click="search(); dialog.detail = false;"
                    >
                        확인
                    </v-btn>

                    <!-- 초기화 -->
                    <v-btn
                        width="110"
                        height="40"
                        depressed
                        dark
                        color="icu_pink"
                        class="font-weight-bold rounded-10"
                        @click="reset_keyword()"
                    >
                        초기화
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
import Header from '../components/Header'

export default {
    components: {
        Header
    },

    props: [
        "from"
    ],

    data: () => ({
        select_list: {
            // 국가 목록
            country_list: [],

            season_nation: [
                "전체",
                "국내판",
                "북미판",
                "일본판",
            ],

            season: {
                en: [],
                kr: [],
                jp: [],
            },

            text_type: [
                "대회이름",
                "우승자",
                "주최"
            ],

            date: [
                "전체",
                "3개월",
                "6개월",
                "12개월",
            ],

            league_type: [],

            continent: [
                "전체",
                "대한민국",
                "일본",
                "아시아",
                "아프리카",
                "유럽",
                "오세아니아",
                "북아메리카",
                "남아메리카",
                "남극",
            ],

            age_group: [
                "전체연령",
                "마스터",
                "시니어",
                "주니어"
            ],

            regulation: [
                { text: "전체", value: "전체" },
                { text: "스탠다드 대한민국", value: "스탠다드_대한민국" },
                { text: "스탠다드 일본", value: "스탠다드_일본" },
                { text: "스탠다드 인터내셔널", value: "스탠다드_인터내셔널" },
                { text: "스탠다드 아시아", value: "스탠다드_아시아" },
                { text: "익스팬디드", value: "익스팬디드" },
            ],
        },

        // 키워드
        keyword: {
            // 기본
            date_type: "완료됨",
            text_type: "대회이름",
            text: "",

            // 상세
            date: "3개월",
            league_type: "전체",
            continent: "전체",
            season_nation : "전체",
            season: "",
            age_group: "전체연령",
            regulation: "전체"
        },

        // 키워드 칩 목록
        keyword_list: [
            {
                type: "date",
                value: "3개월",
            }
        ],

        // 목록 & 페이징
        list_type: "card",
        list: [],
        currentPageList: [],
        page: 1,
        pageCount: 1,

        // 정렬
        sequence: "날짜",
        align_date: false,
        align_title: false,
        align_total: false,
        align_winner: false,

        // Dialog
        dialog: {
            // 상세조건
            detail: false
        },

        // Key
        componentKey: 0,

        loading: false,

        prevRoute: null
    }),

    computed:{
        // 시리즈
        season_list(){
            switch(this.keyword.season_nation){
                case '전체' : return []
                case '국내판' : return this.select_list.season.kr
                case '북미판' : return this.select_list.season.en
                case '일본판' : return this.select_list.season.jp
            }
        },
    },

    async mounted(){
        // 국가 목록 불러오기
        this.select_list.country_list = await this.$load_country_list()

        if(this.$route.query.text){
            this.keyword.text = this.$route.query.text
        }

        if(this.$route.query.text_type){
            this.keyword.text_type = this.$route.query.text_type
        }

        if(this.$route.query.date_type){
            this.keyword.date_type = this.$route.query.date_type
        }

        if(this.$route.query.list_type){
            this.list_type = this.$route.query.list_type
        }

        if(this.from && this.from.path == "/official_league/read"){
            // 컴포넌트에서 keyword_list 가져오기
            this.keyword_list = this.$store.state.keyword_list

            if(this.$store.state.keyword_list.length == 0){
                this.keyword.date = "전체"
            }

            else{
                this.keyword_list.forEach(item => {
                    switch (item.type) {
                        case 'date':
                        this.keyword.date = item.value;
                        break;
                        case 'league_type':
                        this.keyword.league_type = item.value;
                        break;
                        case 'continent':
                        this.keyword.continent = item.value;
                        break;
                        case 'season_nation':
                        this.keyword.season_nation = item.value;
                        break;
                        case 'season':
                        this.keyword.season = item.value;
                        break;
                        case 'age_group':
                        this.keyword.age_group = item.value;
                        break;
                        case 'regulation':
                        this.keyword.regulation = item.value;
                        break;
                    }
                })

                // date가 keyword_list에 없으면 '전체'로 설정
                if (!this.keyword_list.some(item => item.type == 'date')) {
                    this.keyword.date = '전체'
                }
            }
        }

        // 검색
        this.search()

        // 공식대회 리그유형 목록 불러오기
        this.load_official_league_type_list()

        // 제품명 시즌 목록 불러오기
        this.loadSeasonList()
    },

    watch: {
        keyword_list: {
            handler() {
                // 컴포넌트에서 keyword_list 저장
                this.$store.commit('setKeywordList', this.keyword_list)

                this.search()
            },
            deep: true
        },

        page(){
            this.$router.replace({ query: { ...this.$route.query, page: this.page }})
            this.currentPageList = this.list.slice((this.page-1)*12,(this.page)*12)
        },

        // 순서
        sequence(){
            this.sortList()
        },

        // 순서
        align_date(){
            this.sortList()
        },

        // 순서
        align_title(){
            this.sortList()
        },

        // 순서
        align_total(){
            this.sortList()
        },

        // 순서
        align_winner(){
            this.sortList()
        }
    },

    methods: {
        // 검색
        search(){
            this.loading = true

            this.$http.post("/api/official_league/select/search", {
                params: {
                    keyword: this.keyword
                }
            }).then((res) => {
                // 주소 page 불러오기
                if(this.$route.query.page){
                    this.page = parseInt(this.$route.query.page)
                }

                // console.log(res)
                // this.list = res.data

                if(this.$store.state.is_logined && (this.$store.state.type == "관리자" || this.$store.state.type == "운영진")){
                    this.list = res.data
                }
                else{
                    this.list = res.data.filter(e => e.league_type != "너정다 대회")
                }

                this.currentPageList = this.list.slice((this.page-1)*12,(this.page)*12)
                this.pageCount = Math.ceil(res.data.length/12)
                window.scrollTo(0, 0)

                this.loading = false
            })
        },

        // 공식대회 리그유형 목록 불러오기
        load_official_league_type_list(){
            this.$http.get('/api/admin/league/official_league_type_list/list')
            .then((res) => {
                this.select_list.league_type = []
                this.select_list.league_type = res.data.map(e => e.league_type)
                this.select_list.league_type.unshift("전체")
            })
        },

        // 제품명 시즌 목록 불러오기
        loadSeasonList(){
            // 영어
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "en"
                }
            }).then(res => {
                this.select_list.season.en = res.data.map(a => a.name)
            })

            // 한글
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.season.kr = res.data.map(a => a.name)
            })

            // 일어
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "jp"
                }
            }).then(res => {
                this.select_list.season.jp = res.data.map(a => a.name)
            })
        },

        // 순서
        sortList(){
            if(this.sequence == "날짜")
            {
                this.list = this.list.sort((a, b) => new Date(b.start_date) - new Date(a.start_date))
                if(this.align_date){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "대회 이름")
            {
                this.list = this.list.sort((a, b) => a.title_kr.localeCompare(b.title_kr))
                if(this.align_title){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "참가자")
            {
                this.list = this.list.sort((a, b) => b.total - a.total)
                if(this.align_total){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "우승자")
            {
                this.list = this.list.sort((a, b) => {
                    const winnerA = a.winner || a.winner_local || "-";
                    const winnerB = b.winner || b.winner_local || "-";
                    if (winnerA === "-" || winnerA === "" || winnerA === null) return 1;
                    if (winnerB === "-" || winnerB === "" || winnerB === null) return -1;
                    return winnerA.localeCompare(winnerB);
                });

                if(this.align_winner){
                    this.list = this.list.reverse()
                }
            }

            this.currentPageList = this.list.slice((this.page-1)*12,(this.page)*12)
            this.componentKey++
        },

        text_changed(){
            this.$router.replace({ query: { ...this.$route.query, text: this.keyword.text }})
        },

        text_type_changed(){
            this.$router.replace({ query: { ...this.$route.query, text_type: this.keyword.text_type }})
        },
        
        date_type_changed(){
            this.$router.replace({ query: { ...this.$route.query, date_type: this.keyword.date_type }})
        },

        list_type_changed(){
            this.$router.replace({ query: { ...this.$route.query, list_type: this.list_type }})
        },

        // 키워드 초기화
        reset_keyword() {
            this.keyword_list = []
            this.keyword = {
                date_type: "완료됨",
                text_type: "대회이름",
                text: "",

                // 상세조건
                date: "전체",
                league_type: "전체",
                continent: "전체",

                season_nation: "전체",
                season: "",

                age_group: "전체연령",
                regulation: "전체"
            }

            this.search()
        },

        // 키워드 목록 타입 한글로 표시
        pretty_keyword(type){
            switch(type){
                case 'date' : return "날짜"
                case 'league_type' : return "유형"
                case 'continent' : return "대륙"
                case 'season' : return "시즌"
                case 'age_group' : return "연령"
                case 'regulation' : return "레귤"
            }
        },

        // 키워드 목록에서 상세조건 제거
        removeKeyword(item) {
            this.keyword_list = this.keyword_list.filter(k => k !== item)

            if(item.type == "date"){
                this.keyword.date = "전체"
            }

            else if(item.type == "league_type"){
                this.keyword.league_type = "전체"
            }

            else if(item.type == "continent"){
                this.keyword.continent = "전체"
            }

            else if(item.type == "season"){
                this.keyword.season_nation = "전체"
                this.keyword.season = ""
            }

            else if(item.type == "age_group"){
                this.keyword.age_group = "전체"
            }

            else if(item.type == "regulation"){
                this.keyword.regulation = "전체"
            }
        },
        
        // 키워드 목록 업데이트
        updateKeywordList(type, value){
            if(type == "날짜"){
                const existingKeyword = this.keyword_list.find(e => e.type === "date")
                if (existingKeyword) {
                    existingKeyword.value = value
                } else {
                    this.keyword_list.push(
                        {
                            type: "date",
                            value: value,
                        }
                    )
                }
            }

            else if(type == "대회 유형"){
                const existingKeyword = this.keyword_list.find(e => e.type === "league_type")
                if (existingKeyword) {
                    existingKeyword.value = value
                } else {
                    this.keyword_list.push(
                        {
                            type: "league_type",
                            value: value,
                        }
                    )
                }
            }

            else if(type == "국가별 (대륙)"){
                const existingKeyword = this.keyword_list.find(e => e.type === "continent")
                if (existingKeyword) {
                    existingKeyword.value = value
                } else {
                    this.keyword_list.push(
                        {
                            type: "continent",
                            value: value,
                        }
                    )
                }
            }

            else if(type == "시즌 (환경)"){
                const existingKeyword = this.keyword_list.find(e => e.type === "season")
                if (existingKeyword) {
                    existingKeyword.value = value
                } else {
                    this.keyword_list.push(
                        {
                            type: "season",
                            value: value,
                        }
                    )
                }
            }

            else if(type == "연령"){
                const existingKeyword = this.keyword_list.find(e => e.type === "age_group")
                if (existingKeyword) {
                    existingKeyword.value = value
                } else {
                    this.keyword_list.push(
                        {
                            type: "age_group",
                            value: value,
                        }
                    )
                }
            }

            else if(type == "레귤레이션"){
                const existingKeyword = this.keyword_list.find(e => e.type === "regulation")
                if (existingKeyword) {
                    existingKeyword.value = value
                } else {
                    this.keyword_list.push(
                        {
                            type: "regulation",
                            value: value,
                        }
                    )
                }
            }

            this.search()
        },

        computed_player_kr(item) {
            console.log(item)
            
            if(!item.winner) return "-"

            if(item.winner_local){
                return item.winner_local
            }
            else{
                if(item.winner_country != "KR"){
                    return item.winner
                }else{
                    if(item.is_masking != null){
                        if(item.is_masking){
                            return item.winner[0] + '✱' + item.winner.slice(2)
                        }else{
                            return item.winner
                        }
                    }
                    else{
                        if(item.winner_age_group != '시니어' && item.winner_age_group != '주니어'){
                            return item.winner
                        }else{
                            return item.winner[0] + '✱' + item.winner.slice(2)
                        }
                    }
                }
            }
        },

        computed_player_en(item) {
            if(item.winner_country != "KR"){
                return item.winner_en
            }else{
                if(item.is_masking != null){
                    if(item.is_masking){
                        return item.winner_en.split(' ')[0] + ' *'
                    }else{
                        return item.winner_en
                    }
                }
                else{
                    if(item.winner_age_group != '시니어' && item.winner_age_group != '주니어'){
                        return item.winner_en
                    }else{
                        return item.winner_en.split(' ')[0] + ' *'
                    }
                }
            }
        },
    }
}
</script>
<style scoped>
/* # 카드검색(List) 에서 가져옴 */


/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}

::v-deep .pc .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .pc .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* .v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
} */
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}
</style>
<style scoped>
/* 페이징 icon */
::v-deep .mobile .v-pagination__navigation .v-icon{
    color:white;
}


/* 모바일 카테고리 */
::v-deep .mobile .v-select {
    min-height: 33px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-select fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .mobile .v-select .v-input__slot {
    height: 33px;
    min-height: 33px;
    padding-top: 0;
    padding-bottom: 0;
}

::v-deep .mobile .v-select .v-select__selections {
    min-height: 33px !important;
    height: 33px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

::v-deep .mobile .v-select .v-select__slot {
    font-size: 12px; /* 글꼴 크기 조절 */
    height: 33px;
}

::v-deep .mobile .v-select .v-input__append-inner,
::v-deep .mobile .v-select .v-input__prepend-inner {
    margin-top: 0;
}

/* v-switch 라벨 폰트 크기 조절 */
::v-deep .mobile .v-input--switch .v-label {
    font-size:11px !important;
}

/* 모바일 카테고리 */
::v-deep .mobile .v-text-field {
    min-height: 33px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-text-field fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .mobile .v-text-field .v-input__slot {
    height: 33px;
    min-height: 33px;
    padding-top: 0;
    padding-bottom: 0;
}

::v-deep .mobile .v-text-field .v-select__selections {
    min-height: 33px !important;
    height: 33px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

::v-deep .mobile .v-text-field .v-text-field__slot {
    font-size: 12px !important; /* 글꼴 크기 조절 */
    height: 33px;
    /* margin-left: 12px; */
}

/* v-switch 라벨 폰트 크기 조절 */
::v-deep .v-input--switch .v-label {
    font-size:10px !important;
}

::v-deep .mobile .v-v-select__slot {
    font-size:12px !important;
}

::v-deep .mobile .v-select__selections .v-label {
    font-size:12px !important;
}

::v-deep .mobile .v-label {
    font-size:12px !important;
}

::v-deep button {
    min-width: 10px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

/* 페이징 */
::v-deep .mobile .v-pagination__item {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    min-width: 0px !important;
    width: 24px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* v-select > 선택값 글자 */
::v-deep .v-select__selection, .v-select__selection--comma, .v-select.v-text-field input {
    font-size:10px !important;
}

/* v-text-field, v-autocomplete > 입력값 글자 */
.v-text-field input {
    font-size:10px;
}
</style>