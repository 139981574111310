<template>
    <div>
        <!-- 상단 -->
        <Header
            :from="from"
        />

        <div
            id="capture"
        >
            <!-- 상단 -->
            <v-sheet
                class="rounded-15 custom-background"
            >
                <!-- 그라디언트 디자인 -->
                <v-sheet class="gradient-div"></v-sheet>

                <!-- 정보 -->
                <div
                    class="d-flex pa-5 pr-3 pb-3"
                >
                    <!-- 좌측 정보 -->
                    <div>
                        <div
                            class="d-flex mb-2"
                        >
                            <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-12">
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        style="width:72px;"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-img
                                            v-if="league_nation"
                                            width="48"
                                            height="36"
                                            class="rounded-lg mx-auto mt-2"
                                            style="box-shadow:0px 2px 4px #ddd;"
                                            :src="'/upload/flags/'+league_nation.toLowerCase()+'.svg'"
                                        ></v-img>
                                    </div>
                                </template>
                                <div class="text-center pa-1">
                                    {{$country_name(league_nation, country_list)}}
                                </div>
                            </v-tooltip>

                            <div
                                class="ml-2"
                            >
                                <p
                                    class="mb-0"
                                    style="font-size:20px; font-weight:600;"
                                >
                                    {{title_kr}}

                                    <span
                                        class="text-body-2 font-weight-bold"
                                        style="vertical-align:23%;"
                                        :style="$age_group_color(age_group)"
                                    >
                                        {{age_group}}
                                    </span>
                                </p>
                                <p
                                    class="mb-0"
                                    style="font-size:12px; color:#B3B3C2; font-weight:500;"
                                >
                                    {{title_en}}
                                </p>
                            </div>
                        </div>

                        <v-sheet
                            class="rounded-10 d-flex align-center mt-2"
                            color="transparent"
                        >
                            <div
                                style="width:72px;"
                            >
                                <v-img
                                    v-if="regulation"
                                    class="mx-auto"
                                    style="margin-top:-8px;"
                                    height="60"
                                    width="60"
                                    contain
                                    :src="require('@/assets/official_league/'+regulation+'.png')"
                                ></v-img>
                            </div>

                            <!-- 글자 -->
                            <div
                                class="ml-2 mt-2"
                                style="cursor:pointer;"
                            >
                                <!-- 2단 (상세정보) -->
                                <div
                                    class="d-flex"
                                >
                                    <div>
                                        <!-- 대회유형 -->
                                        <p
                                            class="text-subtitle-2 font-weight-regular mb-0 mt-0"
                                            style="color:#ADAFCA;"
                                        >
                                            대회유형
                                        </p>
                                        <!-- 대회유형 -->
                                        <p
                                            class="font-weight-medium text-subtitle-2 mb-0 mt-0"
                                        >
                                            {{league_type}}
                                        </p>
                                    </div>

                                    <div
                                        style="background:#eeeeee; width:1px; height:40px; margin:4px 16px;"
                                    ></div>

                                    <div>
                                        <!-- 대회날짜 -->
                                        <p
                                            class="text-subtitle-2 font-weight-regular mb-0 mt-0"
                                            style="color:#ADAFCA;"
                                        >
                                            대회날짜
                                        </p>
                                        <!-- 대회날짜 -->
                                        <p
                                            class="font-weight-medium text-subtitle-2 mb-0 mt-0"
                                        >
                                            {{dayjs(start_date).format('YYYY-MM-DD')}} ~ {{dayjs(end_date).format('DD')}}
                                        </p>
                                    </div>

                                    <div
                                        style="background:#eeeeee; width:1px; height:40px; margin:4px 16px;"
                                    ></div>

                                    <div>
                                        <!-- 참가자 -->
                                        <p
                                            class="text-subtitle-2 font-weight-regular mb-0 mt-0"
                                            style="color:#ADAFCA;"
                                        >
                                            참가자
                                        </p>
                                        <!-- 참가자 -->
                                        <p
                                            class="font-weight-medium text-subtitle-2 mb-0 mt-0 pb-2px"
                                            style="cursor:pointer;"
                                        >
                                            {{total}}명
                                        </p>
                                    </div>

                                    <div
                                        class="ml-6"
                                    >
                                        <!-- 2일차 -->
                                        <p
                                            class="text-subtitle-2 font-weight-regular mb-0 mt-0"
                                            style="color:#ADAFCA;"
                                        >
                                            2일차
                                        </p>
                                        <!-- 2일차 -->
                                        <p
                                            class="font-weight-medium text-subtitle-2 mb-0 mt-0 pb-2px"
                                            style="cursor:pointer;"
                                        >
                                            {{total2}}명
                                        </p>
                                    </div>

                                    <div
                                        style="background:#eeeeee; width:1px; height:40px; margin:4px 16px;"
                                    ></div>

                                    <div>
                                        <!-- 우승 -->
                                        <p
                                            class="text-subtitle-2 font-weight-regular mb-0 mt-0"
                                            style="color:#ADAFCA;"
                                        >
                                            우승
                                        </p>
                                        <!-- 우승 -->
                                        <p
                                            class="font-weight-medium text-subtitle-2 mb-0 mt-0 pb-2px icu_purple--text"
                                            style="cursor:pointer;"
                                        >
                                            <!-- 국가 -->
                                            <div
                                                v-if="standing_list.length"
                                                style="width:200px; font-size:14px; cursor:pointer;"
                                                class="d-flex align-center font-weight-medium flex-grow-1"
                                                @click="$router.push('/official_league/player/detail?id='+standing_list[0].uid)"
                                            >
                                                <div>
                                                    <v-img
                                                        width="18"
                                                        height="13"
                                                        class="rounded-4 mx-auto"
                                                        :src="'/upload/flags/'+standing_list[0].player_country.toLowerCase()+'.svg'"
                                                    ></v-img>
                                                </div>
                                                <div
                                                    class="ml-2"
                                                >
                                                    <p
                                                        style="font-size:14px; font-weight:600;"
                                                        class="mb-0"
                                                    >
                                                        {{computed_player_kr()}}
                                                    </p>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </v-sheet>
                    </div>

                    <v-spacer></v-spacer>

                    <!-- 우측 정보 -->
                    <div
                        style="position:relative; width:280px;"
                    >
                        <!-- 이미지 -->
                        <v-img
                            class="rounded-10"
                            height="100"
                            width="100"
                            :src="'/upload/official_league/'+image"
                        ></v-img>

                        <!-- 버튼 -->
                        <div
                            class="d-flex"
                            style="position:absolute; top:0px; right:12px;"
                        >
                            <!-- 링크 -->
                            <v-btn
                                depressed
                                color="primary"
                                dark
                                class="ml-1 rounded-10"
                                small
                                height="36"
                                width="36"
                                @click="copyReferenceURL()"
                            >
                                <v-icon size="18">mdi-link-variant</v-icon>
                            </v-btn>

                            <!-- 이미지 캡처 -->
                            <v-btn
                                depressed
                                color="#ADAFCA"
                                dark
                                class="ml-1 rounded-10"
                                small
                                height="36"
                                width="36"
                                @click="capture()"
                            >
                                <v-icon size="18">mdi-image-outline</v-icon>
                            </v-btn>

                            <!-- 공유하기 (현재주소) -->
                            <v-btn
                                depressed
                                color="#ADAFCA"
                                dark
                                class="ml-1 rounded-10"
                                small
                                height="36"
                                width="36"
                                @click="copyURL()"
                            >
                                <v-icon size="18">mdi-share-variant-outline</v-icon>
                            </v-btn>

                            <!-- 수정하기 -->
                            <v-btn
                                v-if="$store.state.type == '관리자' || ($store.state.type == '운영진' && $store.state.permission_official_league)"
                                depressed
                                color="#ADAFCA"
                                dark
                                class="ml-1 rounded-10"
                                small
                                height="36"
                                width="36"
                                @click="$router.push('/official_league/update?id='+$route.query.id)"
                            >
                                <v-icon size="18">mdi-cog-outline</v-icon>
                            </v-btn>
                        </div>

                        <!-- 주최 -->
                        <p
                            class="mb-0 text-caption"
                            style="position:absolute; bottom:8px; right:16px;"
                        >
                            주최
                            <span style="font-weight:600;">{{organizer}}</span>
                        </p>
                    </div>
                </div>
            </v-sheet>

            <!-- 2단 -->
            <v-sheet
                class="d-flex rounded-15 py-3 px-4 mt-2"
                style="position:relative;"
            >
                <div
                    style="position:absolute; bottom:0px; left:32px;"
                >
                    <v-btn
                        height="50"
                        class="px-1 mr-8"
                        style="font-size:16px; font-weight:400; letter-spacing:-1px; min-width:0px;"
                        text
                        tile
                        depressed
                        v-ripple="false"
                        :color="menu == '결과'? 'primary':'#ccc'"
                        :style="menu == '결과'? 'border-bottom:3px solid #23D2E2;':'border-bottom:3px solid #FFFFFF;'"
                        :dark="menu == '결과'"
                        @click="menu = '결과'"
                    >
                        결과
                    </v-btn>

                    <v-btn
                        height="50"
                        class="px-1 mr-8"
                        style="font-size:16px; font-weight:400; letter-spacing:-1px; min-width:0px;"
                        text
                        tile
                        depressed
                        v-ripple="false"
                        :color="menu == '덱 레시피'? 'primary':'#ccc'"
                        :style="menu == '덱 레시피'? 'border-bottom:3px solid #23D2E2;':'border-bottom:3px solid #FFFFFF;'"
                        :dark="menu == '덱 레시피'"
                        @click="menu = '덱 레시피'"
                    >
                        덱 레시피
                    </v-btn>

                    <v-btn
                        height="50"
                        class="px-1 mr-8"
                        style="font-size:16px; font-weight:400; letter-spacing:-1px; min-width:0px;"
                        text
                        tile
                        depressed
                        v-ripple="false"
                        :color="menu == '통계'? 'primary':'#ccc'"
                        :style="menu == '통계'? 'border-bottom:3px solid #23D2E2;':'border-bottom:3px solid #FFFFFF;'"
                        :dark="menu == '통계'"
                        @click="menu = '통계'"
                    >
                        통계
                    </v-btn>

                    <v-btn
                        height="50"
                        class="px-1 mr-8"
                        style="font-size:16px; font-weight:400; letter-spacing:-1px; min-width:0px;"
                        text
                        tile
                        depressed
                        v-ripple="false"
                        :color="menu == '대회 상세정보'? 'primary':'#ccc'"
                        :style="menu == '대회 상세정보'? 'border-bottom:3px solid #23D2E2;':'border-bottom:3px solid #FFFFFF;'"
                        :dark="menu == '대회 상세정보'"
                        @click="menu = '대회 상세정보'"
                    >
                        대회 상세정보
                    </v-btn>

                    <v-btn
                        height="50"
                        class="px-1 mr-8"
                        style="font-size:16px; font-weight:400; letter-spacing:-1px; min-width:0px;"
                        text
                        tile
                        depressed
                        v-ripple="false"
                        :color="menu == '관련대회'? 'primary':'#ccc'"
                        :style="menu == '관련대회'? 'border-bottom:3px solid #23D2E2;':'border-bottom:3px solid #FFFFFF;'"
                        :dark="menu == '관련대회'"
                        @click="menu = '관련대회'"
                    >
                        관련대회
                    </v-btn>
                </div>

                <v-spacer></v-spacer>
                <p
                    class="mb-0 pt-5px mr-6 text-body-2"
                >
                    <span class="icu_purple--text font-weight-bold">시즌</span>

                    {{season_name}}
                </p>
                <v-btn
                    v-if="$store.state.type == '관리자'"
                    depressed
                    color="icu_purple"
                    class="rounded-lg px-6"
                    small
                    dark
                    @click="dialog.player = true"
                >
                    수정
                </v-btn>

                <v-btn
                    depressed
                    color="icu_purple"
                    class="rounded-lg px-6 ml-2"
                    small
                    dark
                    @click="dialog.provide = true"
                >
                    제보
                </v-btn>
            </v-sheet>

            <!-- 본문 -->
            <v-sheet
                class="rounded-15 mb-4 mt-2"
            >
                <div>
                    <v-sheet
                        v-if="menu == '결과'"
                        class="mt-1 rounded-15"
                    >
                        <Results />
                    </v-sheet>

                    <v-sheet
                        v-if="menu == '통계'"
                        class="pt-1 rounded-15"
                    >
                        <Statistics
                            :image="image"
                        />
                    </v-sheet>

                    <v-sheet
                        v-if="menu == '덱 레시피'"
                        class="pt-1 rounded-15"
                    >
                        <DeckRecipe
                            :title_kr="title_kr"
                        />
                    </v-sheet>

                    <div
                        v-if="menu == '관련대회'"
                    >
                        <SameTypeList />
                    </div>

                    <v-sheet
                        v-if="menu == '대회 상세정보'"
                        class="px-4 rounded-15"
                    >
                        <TipTapReader 
                            v-if="options.content"
                            class="tiptap_style"
                            :options="options"
                        />
                    </v-sheet>
                </div>
            </v-sheet>
        </div>

        <!-- 플레이어 입력/수정 -->
        <v-dialog
            v-model="dialog.player"
            width="auto"
            content-class="rounded-15"
        >
            <PlayerManage
                :title_kr="title_kr"
                :season_nation="season_nation"
                :season_name="season_name"
                :league_type="league_type"
                @close="dialog.player = false; load_standing_list()"
            />
        </v-dialog>

        <!-- 제보 -->
        <v-dialog
            v-model="dialog.provide"
            width="auto"
            content-class="rounded-15"
        >
            <ReportDialog
                :title_kr="title_kr"
                @close="dialog.provide = false"
            />
        </v-dialog>

        <!-- 카드샵 입력/수정 -->
        <v-dialog
            v-model="dialog.cardshop"
            width="auto"
            content-class="rounded-15"
        >
            <CardShopManage
                :title_kr="title_kr"
                @close="dialog.cardshop = false; load_card_shop_standing_list()"
            />
        </v-dialog>
    </div>
</template>
<script>
import TipTapReader from "@/components/tiptap/Reader"
import Header from '../components/Header'
import PlayerManage from '../components/read/PlayerManage'
import CardShopManage from '../components/read/CardShopManage'
import ReportDialog from '../components/read/ReportDialog'
import html2canvas from 'html2canvas'

import Results from '../components/read/Results'
import Statistics from '../components/read/Statistics'
import DeckRecipe from '../components/read/DeckRecipe'
import SameTypeList from '../components/read/SameTypeList'

export default {
    components: {
        Header,
        TipTapReader,
        PlayerManage,
        CardShopManage,
        ReportDialog,
        html2canvas,
        Results,
        Statistics,
        DeckRecipe,
        SameTypeList
    },

    props: [
        "from"
    ],

    data: () => ({
        title_kr: "",
        title_en: "",
        title_local: "",
        regulation: "",
        league_type: "",
        image: "",
        organizer: "",
        reference_link: "",
        start_date: "",
        end_date: "",
        total: 0,
        total2: 0,
        content: "",
        menu: "결과",
        sub_menu: "플레이어",
        list: [],
        season_nation: "",
        season_name: "",
        age_group: "",
        league_nation: "",

        //TipTap
        options: {
            content: "",
            readonly: true,
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        dialog: {
            player: false,
            provide: false,
            cardshop: false
        },

        selected_item: {},
        componentKey: 0,

        standing_list: [],
        country_list: [],
    }),

    // # 새로 못 불러오는 문제 해결
    watch: {
        '$route': {
            handler() {
                this.handleRouteChange()
            },
            immediate: true
        },
    },

    async mounted(){
        await this.fetchData(this.$route.query.id)
    },

    async beforeRouteUpdate(to, from, next) {
        if (from.path === to.path && from.query.id === to.query.id) {
            return next(false)
        }

        await this.fetchData(to.query.id)
        next()
    },

    methods: {
        async handleRouteChange() {
            await this.fetchData(this.$route.query.id)
        },

        async fetchData(id) {
            // 국가 목록 불러오기
            this.country_list = await this.$load_country_list()

            // 공식대회 정보 불러오기
            await this.load(id)

            // 검색
            await this.search(id)

            this.load_standing_list(id)

            this.menu = '결과'
        },

        // 공식대회 스탠딩 목록 불러오기
        load_standing_list(id){
            this.$http.post("/api/official_league/select/standing", {
                params: {
                    league_id: id
                }
            }).then((res) => {
                if(res.data.length){
                    this.standing_list = res.data
                    // console.log(res.data)
                }
            })
        },

        // 검색
        search(id){
            this.$http.post("/api/official_league/select/sameType", {
                params: {
                    organizer: this.organizer,
                    league_type: this.league_type
                }
            }).then((res) => {
                // console.log(res)
                this.list = res.data.filter(e => e.id != id)
            })
        },

        // 공식대회 정보 불러오기
        async load(id){
            await this.$http.post("/api/official_league/select/specific", {
                params: {
                    id: id
                }
            }).then(async(res) => {
                if(res.data.length){
                    // console.log(res)
                    this.title_kr = res.data[0].title_kr
                    this.title_en = res.data[0].title_en
                    this.title_local = res.data[0].title_local
                    this.regulation = res.data[0].regulation
                    this.league_type = res.data[0].league_type
                    this.image = res.data[0].image
                    this.organizer = res.data[0].organizer
                    this.reference_link = res.data[0].reference_link
                    this.start_date = res.data[0].start_date
                    this.end_date = res.data[0].end_date
                    this.age_group = res.data[0].age_group
                    this.options.content = res.data[0].content
                    this.total = res.data[0].total
                    this.total2 = res.data[0].total2
                    this.league_nation = res.data[0].league_nation

                    this.season_nation = res.data[0].season_nation
                    this.season_name = res.data[0].season_name
                }
            })
        },

        // 캡처
        capture(){
            const captureElement = document.getElementById('capture')

            html2canvas(
                captureElement,
                {
                    scale: 2,
                    useCORS: true,
                }
            )
            .then(canvas => {
                const dataUrl = canvas.toDataURL('image/png')
                const img = document.createElement('img')
                img.src = dataUrl
                document.body.appendChild(img)
                const a = document.createElement('a')
                a.href = dataUrl
                a.download = this.title_kr
                a.click()

                // 이미지와 링크 요소 제거
                document.body.removeChild(img)
                a.remove()
            })
        },

        // 대회 관련 링크 복사
        copyReferenceURL(){
            if(this.reference_link == ""){
                alert("관련링크가 없습니다")
            }else{
                window.open(this.reference_link, '_blank')
            }
        },

        // 대회 주소 복사
        copyURL(){
            this.$copyText(window.location.origin + this.$route.fullPath)
            alert('대회 주소가 복사되었습니다')
        },

        computed_player_kr(item) {
            if(this.standing_list[0].player_local){
                return this.standing_list[0].player_local
            }
            else{
                if(this.standing_list[0].player_country != "KR"){
                    return this.standing_list[0].player_kr
                }else{
                    if(this.standing_list[0].is_masking != null){
                        if(this.standing_list[0].is_masking){
                            return this.standing_list[0].player_kr[0] + '✱' + this.standing_list[0].player_kr.slice(2)
                        }else{
                            return this.standing_list[0].player_kr
                        }
                    }
                    else{
                        if(this.standing_list[0].player_age_group != '시니어' && this.standing_list[0].player_age_group != '주니어'){
                            return this.standing_list[0].player_kr
                        }else{
                            return this.standing_list[0].player_kr[0] + '✱' + this.standing_list[0].player_kr.slice(2)
                        }
                    }
                }
            }
        },

        computed_player_en(item) {
            if(this.standing_list[0].player_country != "KR"){
                return this.standing_list[0].player_en
            }else{
                if(this.standing_list[0].is_masking != null){
                    if(this.standing_list[0].is_masking){
                        return this.standing_list[0].player_en.split(' ')[0] + ' *'
                    }else{
                        return this.standing_list[0].player_en
                    }
                }
                else{
                    if(this.standing_list[0].player_age_group != '시니어' && this.standing_list[0].player_age_group != '주니어'){
                        return this.standing_list[0].player_en
                    }else{
                        return this.standing_list[0].player_en.split(' ')[0] + ' *'
                    }
                }
            }
        },
    }
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}
</style>
<style scoped>
.gradient-div {
    height: 3px;
    width: 100%;
    background: linear-gradient(
        to right, 
        #cc3140 0%, 
        #ee613f 12.6%, 
        #fdb865 26.8%, 
        #fee590 39.3%, 
        #f6f9a8 52.7%, 
        #b8e297 66.5%, 
        #79c69c 77.8%, 
        #5ab3a1 89.1%, 
        #377eae 100%
    );
}
</style>
<style scoped>
.custom-background {
    background-image: url('~@/assets/official_league_summary.png');
    background-position: center 30%;
    background-size: cover;
    overflow-x: hidden;
}
</style>